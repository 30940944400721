import { createRouter, createMemoryHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        redirect: '/home'
    }, {
        path: '/home',
        name: 'home',
        component: () => import('../views/homePage.vue')
    }, {
        path: '/service',
        name: 'loanService',
        component: () => import('../views/loanService.vue')
    }, {
        path: '/about',
        name: 'aboutUS',
        component: () => import('../views/aboutUS.vue')
    }, {
        path: '/contact',
        name: 'contactUS',
        component: () => import('../views/contactUS.vue')
    }, {
        path: '/privatePolicy',
        name: 'privatePolicy',
        component: () => import('../views/privatePolicy.vue')
    }, {
        path: '/loanAgreement',
        name: 'loanAgreement',
        component: () => import('../views/loanAgreement.vue')
    }, {
        path: '/customerPrivatePolicy',
        name: 'customerPrivatePolicy',
        component: () => import('../views/customerPrivatePolicy.vue')
    }
]

const router = createRouter({
    history: createMemoryHistory(),
    routes
})

export default router;
